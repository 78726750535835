// Landing base route
export const LANDING_BASE = '';
// Manage users route
export const USERS_MANAGEMENT = 'user-management';
// Create users route
export const CREATE_USER = 'create';
// Manage conectors
export const CONNECTORS = 'connectors';
// Create connector
export const CREATE_CONNECTOR = 'create';
// Edit connector
export const EDIT_CONNECTOR = 'edit';
// Profile
export const PROFILE = 'my-profile';
// Log
export const LOGS = ':connectorId/logs/:storeId';
export const USERS = 'users/:connectorId';
// Shell select
export const SHELL_SELECT = 'shell-select';
// Integrations
export const INTEGRATIONS = 'integrations';
export const INTEGRATION_SETTINGS = 'settings';
export const INTEGRATION_ERRORS = 'errors';
export const INTEGRATION_CREDENTIALS = 'credentials';
export const INTEGRATION_CUSTOM_ACTIONS = 'custom-actions';
export const INTEGRATION_CUSTOM_TABLE = 'custom-table';
export const CREATE_BULK_CONNECTIONS = 'create-bulk/:integration_id';
export const INTEGRATION_USERS = 'users';
export const ICG_AGENT_DETAILS = 'icg-agent-details';
// Charts
export const DASHBOARDS = 'dashboards';
// Retail update
export const RETAIL_UPDATE = 'retail_update';
// Retail logs
export const RETAIL_LOGS = 'logs';
//3PL aggregator
export const AGGREGATOR = 'aggregator';
export const INTEGRATIONS_3PL = 'integrations/:storeId';
export const LOGS_3PL = 'logs/:id';

export const KIOSK = 'kiosk';
export const KIOSK_BANNER = 'banner';
export const KIOSK_SETTINGS = 'settings';
export const KIOSK_BRANDS = 'brands';
export const KIOSK_CREDENTIALS = 'credentials';
export const KIOSK_CLUSTER = 'cluster';
export const KIOSK_USERS = 'users';
