import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { ROUTES } from 'app/app.routes';
import { AuthModule } from '@auth0/auth0-angular';
import { environment } from 'environments/environment';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NgSelectModule } from '@ng-select/ng-select';
import { SanitizePipe } from '../modules/dashboard/shared/pipes/sanitizer/sanitize.pipe';

/**
 * Core module
 */
@NgModule({
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    RouterModule.forRoot(ROUTES),
    HttpClientModule,
    AuthModule.forRoot({
      domain: environment.auth0Domain,
      clientId: environment.auth0ClientId,
    }),
    MatDialogModule,
    MatSelectModule,
    MatSnackBarModule,
    NgSelectModule,
  ],
  exports: [
    BrowserAnimationsModule,
    BrowserModule,
    RouterModule,
    HttpClientModule,
    MatDialogModule,
    MatSelectModule,
    MatSnackBarModule,
    NgSelectModule,
  ],
})
export class CoreModule {}
