export const environment = {
  production: true,
  connectorApiUrl:
    'https://us-central1-hubster-305121.cloudfunctions.net/serverlog/api/v2',
  userApiUrlV2: 'https://userv2-staging-4df2kcmewq-uc.a.run.app',
  auth0Domain: 'login.hubster.slabcode.com',
  auth0ClientId: 'fxFz1yyAP9sq0Vh58jh1zlErdCHAWW60',
  shellSelectUrl:
    'https://us-central1-hubster-305121.cloudfunctions.net/shell-select-develop',
  loggerServiceV2:
    'https://serverlog-v2-staging-4df2kcmewq-uc.a.run.app/api/v2',
  retailerGateway:
    'https://us-central1-hubster-305121.cloudfunctions.net/retailergateway-dev',
  omoUser: 'https://omo-lavanderias-dev-back-users-4df2kcmewq-uc.a.run.app',
  omoMenu:
    'https://us-central1-hubster-305121.cloudfunctions.net/omo-laundry-menu-development',
  aggregator: 'https://aggregator-staging-4df2kcmewq-uc.a.run.app',
  kfcKioskApi: 'https://{INTEGRATION_NAME}-staging-4df2kcmewq-uc.a.run.app',
  kioskUsers: 'https://kiosks-users-staging-4df2kcmewq-uc.a.run.app',
  kfcKioskFrontend: 'https://staging.kiosk.otter.slabcode.com',
  kiosksManagementApi:
    'https://kiosks-management-staging-4df2kcmewq-uc.a.run.app',
  kiosksManagementApiKey:
    'tT-mI2-QScUdvlr5rZ?iI90Fr8ahMR3uLP1pmmlCKuSTe9AhnlwmPXW2VsZBWT7W063f/-pFVA?4q3MUjZ7v0AJ/9fCBR42IP5xUToTP71HmpuyT74abCKP8a8FP1DBk',
  kioskDashboardUrl: 'https://dev.kiosk-manager.otter.slabcode.com',
  suffix: 'dev',
};
