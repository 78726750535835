import { NgModule } from '@angular/core';
import { CoreModule } from '@custom-modules/core.module';
import { MaterialModule } from '@custom-modules/material.module';
import { SharedModule } from '@custom-modules/shared.module';
import { AppComponent } from './app.component';

/**
 * Root module
 */
@NgModule({
  declarations: [AppComponent],
  imports: [CoreModule, SharedModule, MaterialModule],
  bootstrap: [AppComponent],
})
export class AppModule {}
