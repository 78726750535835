import { Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { LANDING_BASE } from '@utils/constants/routes.constants';

/**
 * Project routes
 */
export const ROUTES: Routes = [
  {
    path: LANDING_BASE,
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
  }
];
